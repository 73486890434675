//convert px to vw unit
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function vwSp($pixel){
  //define 1unit vw
  $vw: 750/100;
  $pixel: strip-unit($pixel);
  @return ($pixel / $vw) * 1 +vw;
}
//convert px to vw unit
@function vwPc($pixel){
  //define 1unit vw
  $vw: strip-unit($pc)/100;
  $pixel: strip-unit($pixel);
  @return ($pixel / $vw) * 1 +vw;
}

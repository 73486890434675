@import "base/_variable";
@import "base/_mixin";
@import "base/_function";
@include sp-layout() {
	footer {
		background-image: none;
		position: relative;
		padding: 0 0 vwSp(148) 0;
		.sp_footer {
			background-color: $cl02;
			&::before {
				content: "";
				width: 100%;
				height: 3px;
				background-color: $cl00;
				display: block;
				border-bottom: solid 1px #fff;
			}
			* {
				color: #fff;
				text-align: center;
			}
			&-info {
				padding: vwSp(80) vwSp(70) vwSp(44);
				p {
					font-weight: 700;
					font-size: vwSp(52);
					span,
					small {
						display: block;
					}
					span {
						font-size: vwSp(28);
						padding: 0 0 vwSp(20) 0;
					}
					small {
						font-weight: 300;
						line-height: 1.8;
						font-size: vwSp(26);
					}
				}
			}
			&-tel {
				p {
					font-weight: 700;
					padding: 0 0 vwSp(50) 0;
					span {
						font-size: vwSp(40);
						padding: 0 4px 0 0;
					}
					a {
						font-size: vwSp(52);
					}
					small {
						display: flex;
						align-items: center;
						justify-content: center;
						font-weight: 300;
						font-size: vwSp(28);
						&::before {
							content: "[";
							display: inline-block;
							padding: 0 4px 0 0;
						}
						&::after {
							content: "]";
							display: inline-block;
							padding: 0 0 0 4px;
						}
					}
				}
				ul {
					background-color: $cl03;
					border: solid 1px $cl04;
					margin: 0 auto vwSp(32);
					width: calc(100% - #{vwSp(180)});
					text-align: left;
					padding: vwSp(30) vwSp(40);
					li {
						display: inline-flex;
						align-items: center;
						font-weight: 700;
						font-size: vwSp(23);
						&::before {
							content: "\25a0";
							display: inline-block;
							padding: 0 4px 0 0;
						}
					}
				}
			}
			.copy {
				background-color: $cl05;
				border-top: solid 1px #fff;
				padding: vwSp(30);
				small {
					font-size: vwSp(24);
				}
			}
		}
		.footer_fix_page-top {
			display: block;
			position: absolute;
			top: vwSp(30);
			right: vwSp(30);
			a {
				text-decoration: none;
				color: $cl02;
				background-color: #fff;
				width: vwSp(80);
				height: vwSp(80);
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				i {
					font-size: vwSp(32);
					line-height: normal;
				}
			}
		}
	}
	#floating {
		//display: none;
		display: flex;
		height: vwSp(148);
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: fixed;
		right: 0;
		left: 0;
		bottom: 0;
		//padding: vwSp(20) 0;
		background: rgb(255, 51, 0);
		background: linear-gradient(180deg, rgba(255, 51, 0, 1) 0%, rgba(255, 102, 51, 1) 100%);
		//border: 2px solid #000;
		span {
			display: block;
			color: #fff;
			font-size: vwSp(40);
			font-weight: 700;
			text-align: center;
		}
		small {
			display: block;
			font-size: vwSp(26);
			font-weight: 500;
			color: #fff;
			text-align: center;
			margin: 0;
			padding: 0;
		}
	}
}
.footer_fix_page-top,
#floating {
	display: none;
}

@import "base/_variable";
@import "base/_mixin";
@import "base/_function";

//　アコーディアオン矢印
@keyframes closeopen {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(540deg);
	}
}
@keyframes openclose {
	0% {
		transform: rotate(540deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
// 共通
* {
	margin: 0;
	padding: 0;
	@include sp-layout() {
		letter-spacing: normal !important;
	}
}
html {
	@include sp-layout() {
		box-sizing: border-box;
		height: 100%;
		letter-spacing: normal;
		height: -webkit-fill-available;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		touch-action: manipulation;
	}
}
body {
	@include sp-layout() {
		font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
		text-align: justify;
		font-weight: 500;
		font-size: vwSp(28);
		line-height: 1.6;
		&.fixed {
			width: 100%;
			height: 100%;
			position: fixed;
		}
	}
}
body,
p,
div,
td,
th,
ul,
li,
address,
h1,
h2,
h3,
h4,
h5,
header,
footer,
asice,
article,
section {
	@include sp-layout() {
		font-size: vwSp(26);
	}
}
ol,
ul {
	list-style: none;
}
img {
	@include sp-layout() {
		max-width: 100%;
		height: auto;
		width: auto;
	}
}
a {
	pointer-events: auto;
	&:hover,
	&:active {
		text-decoration: none;
	}
}
a[href="javascript:void(0)"] {
	cursor: default;
	opacity: 1;
}
img[src*="loging.cgi"],
img[src*="index.cgi"] {
	opacity: 0;
	visibility: hidden;
	display: none;
}
#page-top {
	position: fixed;
	bottom: 20px;
	right: 30px;
	opacity: 0;
	transition: 0.25s;
	@include sp-layout() {
		bottom: vwSp(180);
		right: vwSp(30);
	}
	&.fadein {
		opacity: 1;
	}
	&.fadeout {
		opacity: 0;
	}
	a {
		background-color: $cl00;
		border: solid 1px #fff;
		width: 40px;
		height: 40px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		&:hover {
			background: $cl00;
		}
		i {
			font-size: 16px;
			line-height: normal;
			@include sp-layout() {
				font-size: vwSp(32);
			}
		}
		@include sp-layout() {
			width: vwSp(80);
			height: vwSp(80);
		}
	}
}
@include sp-layout() {
	.f10 {
		font-size: vwSp(20);
	}
	.f11 {
		font-size: vwSp(22);
	}
	.f12 {
		font-size: vwSp(24);
	}
	.f13 {
		font-size: vwSp(26);
	}
	.f14 {
		font-size: vwSp(28);
	}
	.f15 {
		font-size: vwSp(30);
	}
	.f16 {
		font-size: vwSp(32);
	}
	.f17 {
		font-size: vwSp(34);
	}
	.f18 {
		font-size: vwSp(36);
	}
	.f19 {
		font-size: vwSp(38);
	}
	.f20 {
		font-size: vwSp(40);
	}
}
.mainimg {
	img {
		display: block;
		margin: auto;
	}
}
a.accordion {
	text-align: center;
	color: $cl07;
	padding: vwSp(9) 0;
	width: 100%;
	background-color: #fff;
	border: solid 1px $cl07;
	border-radius: vwSp(6);
	position: relative;
	margin: 0 auto;
	&::before {
		content: "\7d9a\304d\3092\8aad\3080";
		font-size: vwSp(26);
		font-weight: 700;
		display: inline-block;
		margin: 0 auto;
	}
	&::after {
		content: "\f107";
		font-weight: 900;
		font-family: "Font Awesome 5 Free";
		display: inline-block;
		position: absolute;
		right: vwSp(20);
		top: 50%;
		transform: translateY(-50%);
	}
	&.open {
		&::before {
			content: "\9589\3058\308b";
		}
		&::after {
			content: "\f077";
		}
	}
}
.pc {
	&-b {
		display: block !important;
		@include sp-layout() {
			display: none !important;
		}
	}
	&-ib {
		display: inline-block !important;
		@include sp-layout() {
			display: none !important;
		}
	}
}
.sp {
	&-b {
		display: none !important;
		@include sp-layout() {
			display: block !important;
		}
	}
	&-ib {
		display: none !important;
		@include sp-layout() {
			display: inline-block !important;
		}
	}
	&-flex {
		display: none !important;
		@include sp-layout() {
			display: flex !important;
		}
	}
}
.cntentsbox {
	margin: 0;
	@include sp-layout() {
		margin: 0 auto;
		float: none;
	}
}
#cntents {
	margin: 15px auto 0;
	@include sp-layout() {
		width: 100%;
		background-color: #efeeea;
	}
}
#pan {
	@include sp-layout() {
		display: none;
	}
}
#rap {
	@include sp-layout() {
		width: 90%;
		text-align: justify;
		float: none;
		.bx {
			ul {
				li {
					.txt {
						width: 100%;
					}
				}
			}
		}
		.samples {
			.boxs {
				p {
					width: 100%;
				}
			}
		}
		hgroup {
			h1 {
				padding: vwSp(20) 0 vwSp(20) vwSp(22);
				border-left: solid 4px $cl02;
				border-bottom: solid 1px $cl02;
				background: rgb(255, 255, 255);
				background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(239, 238, 234, 1) 100%);
				span {
					color: $cl02;
					font-weight: 700;
					font-size: vwSp(32);
				}
			}
		}
		p#ltitle {
			margin-bottom: vwSp(60);
		}
		section {
			h2 {
				background-image: none;
				background-color: $cl02;
				font-size: vwSp(32);
				height: auto;
				text-indent: 0;
				padding: vwSp(12) 0 vwSp(12) vwSp(30);
				margin: vwSp(40) auto;
			}
			h3 {
				&.tits {
					font-size: vwSp(32);
					padding: vwSp(6) 0 vwSp(6) vwSp(16);
				}
			}
			h4 {
				margin: vwSp(20) auto;
				width: 100%;
			}
			table {
				width: 100%;
				border-bottom: solid 1px #555;
				border-left: solid 1px #555;
				min-width: initial;
				th {
					border-top: solid 1px #555;
					border-right: solid 1px #555;
					padding: 6px 10px;
					text-align: center;
					font-weight: 900;
				}
				td {
					border-top: solid 1px #555;
					border-right: solid 1px #555;
					padding: 6px 15px;
					background-color: #f5f5f5;
				}
			}
		}
		.komebox {
			margin-bottom: 0px;
			p,
			span {
				font-size: vwSp(26);
			}
		}
	}
}
#lnavi {
	.space {
		img {
			margin: 0 auto;
		}
	}
	@include sp-layout() {
		width: 100%;
		float: none;
		.space2 {
			width: 95%;
			margin: vwSp(60) auto vwSp(30);
		}
		.side_news,
		.space.exa {
			width: 95%;
			margin: 0 auto;
		}
		.sideh_title {
			margin: 0 auto vwSp(20) !important;
			padding: vwSp(12) 0;
			position: relative;
			font-size: vwSp(30);
		}
		.space.exa {
			margin: vwSp(40) auto vwSp(120) !important;
			> div {
				display: none;
				ul {
					li {
						background-image: none;
						background-color: #f7f7f5;
						position: relative;
						padding: vwSp(30) vwSp(30) vwSp(30) vwSp(40);
						width: calc(100% - #{vwSp(70)});
						span {
							font-size: vwSp(28);
						}
						a {
							font-size: vwSp(32);
						}
						&::before {
							content: "";
							height: 100%;
							width: vwSp(40);
							background-color: #fff;
							position: absolute;
							left: 0;
							bottom: 0;
							top: 0;
						}
						&::after {
							font-weight: 900;
							font-family: "Font Awesome 5 Free";
							content: "\f0da";
							color: #0099cc;
							position: absolute;
							top: 50%;
							left: vwSp(14);
							transform: translateY(-50%);
						}
					}
					+ p {
						a {
							span {
								color: #fff;
								background-color: $cl09;
								display: block;
								padding: vwSp(24) 0;
								width: 100%;
								text-align: center;
								font-weight: 700;
								i {
									padding: 0 4px 0 0;
								}
							}
						}
					}
				}
			}
			.sideh_title {
				&::after {
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					content: "\2b";
					display: inline-block;
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
				&.open {
					&::after {
						content: "\f068";
					}
				}
			}
		}
		.side_news {
			ul {
				li {
					font-size: vwSp(32);
					padding: vwSp(30);
					h3 {
						font-weight: 700;
						font-size: vwSp(32);
					}
					p {
						font-size: vwSp(32);
					}
				}
			}
		}
	}
}
.side_news {
	@include sp-layout() {
		ul {
			width: 100%;
		}
	}
}
#lnavi {
	@include sp-layout() {
		.exa {
			ul {
				width: 100%;
				li {
					background: {
						repeat: repeat;
					}
				}
			}
		}
	}
}
.mainimg {
	@include sp-layout() {
		padding: vwSp(120) 0 0 0;
		overflow: hidden;
		height: 100px;
		img {
			height: 100%;
			max-width: initial;
		}
	}
}
@import "base/_sp_footer";
@import "base/_sp_header";

@import "base/_variable";
@import "base/_mixin";
@import "base/_function";
header {
	@include sp-layout() {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		height: vwSp(120);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
		z-index: 9999;
		.hed {
			width: 100%;
			//height: 100%;
			margin: 0;
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			hgroup {
				padding: vwSp(20) 0 0 vwSp(24);
				h1 {
					font-size: vwSp(20);
					margin: 0 0 vwSp(10);
				}
				h2 {
					display: block;
					height: vwSp(40);
					img {
						height: 100%;
						width: auto;
					}
				}
			}
		}
		.add {
			display: none;
		}
		nav {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			display: none;
			width: 100%;
			height: 100vh;
			background-color: $cl01;
			* {
				color: #fff;
			}
			ul {
				width: 100%;
				display: block;
				padding: vwSp(140) 0 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				li {
					width: 100%;
					a {
						width: 100%;
						display: block;
						span {
							font-size: vwSp(32);
							line-height: 2.5;
						}
					}
				}
			}
		}
		.hamburger {
			position: fixed;
			top: vwSp(30);
			right: vwSp(20);
			// width: vwSp(80);
			// height: vwSp(80);
			width: vwSp(160);
			height: vwSp(64);
			background-color: $cl01;
			border-radius: 4px;
			border: solid 1px #fff;
			z-index: 9999;
			// padding-top: 80px;
			// text-align: center;
			// box-sizing: border-box;
			// counter-increment: item;
			// &::after {
			// 	position: absolute;
			// 	top: 1.5em;
			// 	left: 1.5em;
			// 	width: 40px;
			// 	height: 40px;
			// 	border: 1px solid #fff;
			// 	content: counter(item);
			// 	color: #fff;
			// 	font-size: 20px;
			// 	text-align: center;
			// 	line-height: 40px;
			// }
		}
		.menu-trigger {
			position: relative;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			appearance: none;
			cursor: pointer;
			// span {
			// 	display: inline-block;
			// 	transition: all 0.4s;
			// 	box-sizing: border-box;
			// }
			// span {
			// 	position: absolute;
			// 	left: vwSp(24);
			// 	width: vwSp(32);
			// 	height: 2px;
			// 	background-color: #fff;
			// 	&:nth-of-type(1) {
			// 		top: vwSp(34);
			// 	}
			// 	&:nth-of-type(2) {
			// 		top: vwSp(44);
			// 	}
			// }
			&::before {
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
				content: "\f0ca\0020";
				display: inline-block;
				color: #fff;
				font-weight: 700;
				font-size: vwSp(24);
			}
			&::after {
				content: "\004d\0045\004e\0055";
				display: inline-block;
				color: #fff;
				font-weight: 700;
				font-size: vwSp(24);
				padding: 0 0 0 vwSp(8);
			}
			&.active {
				// transform: rotate(360deg);
				// span {
				// 	&:nth-of-type(1) {
				// 		transform: translateY(#{vwSp(4)}) rotate(-45deg);
				// 	}
				// 	&:nth-of-type(2) {
				// 		transform: translateY(#{vwSp(-6)}) rotate(45deg);
				// 	}
				// }
				&::before {
					content: "\f00d\0020";
				}
				&::after {
					content: "\0043\004c\004f\0053\0045";
				}
			}
		}
	}
}

// // ****************************************************************************
// // *                                 variable                                 *
// // ****************************************************************************
$imgpath: "img/";
//-----------------------
$text_dark: #555555;
$cl00: #040302; //フッターの黒
$cl01: #946e00; //ナビゲーションの背景
$cl02: #4f3514; //フッターメイン茶色
$cl03: #603612; //フッター電話番号の下
$cl04: #927a5f; //フッター電話番号の下枠線
$cl05: #573e1e; //コピーライト
$cl06: #ed7602;
$cl07: #207b1e;
$cl08: #104070;
$cl09: #563d1d;
// // ****************************************************************************
// // *                                 break point                              *
// // ****************************************************************************

$mobile: 600px;
$tb: 1024px;
$pc: 1440px;
$lg: $pc + 1px;
